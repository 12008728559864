import React from 'react';
import Chart from 'react-apexcharts';

const MarketingModelChart = () => {
  const series = [
    {
      name: 'Customers',
      data: [100, 120, 150, 170, 200, 230, 250, 280, 300, 320, 350, 380, 400, 420, 450, 480, 500, 520, 550, 580],
    },
  ];

  const options = {
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
      },
    },
    colors: ['#d05f0d'], // Set the color to #d05f0d
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: ['2024 Q1', '2024 Q2', '2024 Q3', '2024 Q4', '2025 Q1', '2025 Q2', '2025 Q3', '2025 Q4', '2026 Q1', '2026 Q2', '2026 Q3', '2026 Q4', '2027 Q1', '2027 Q2', '2027 Q3', '2027 Q4', '2028 Q1', '2028 Q2', '2028 Q3', '2028 Q4'],
    },
    yaxis: {
      title: {
        text: 'Projected Acquired Customers',
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + ' customers';
        },
      },
    },
  };

  return (
    <div id="chart">
      <Chart options={options} series={series} type="bar" height={350} />
    </div>
  );
};

export default MarketingModelChart;