import React from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import './MarketingModel.css';
import MarketingModelChart from './MarketingModelChart';
import MarketingModelTable from './MarketingModelTable';
import MarketingModelHeader from './MarketingModelHeader';
import CustomerModelHeader from './CustomerModel/CustomerModelHeader';
import CustomerModelTable from './CustomerModel/CustomerModelTable';
import CustomerModelChart from './CustomerModel/CustomerModelChart';
import CustomerModelOrganic from './CustomerModel/CustomerModelOrganic';
import CustomerModelOrganicChart from './CustomerModel/CustomerModelChartOrganic';
import CustomerModelOrganicHeader from './CustomerModel/CustomerModelOrganicHeader';
import CustomersTotalChart from './CustomerModel/CustomersTotalChart';
import RevenueModelTable from './RevenueModel/RevenueModelTable';
import OperatingModel from './OperatingModel';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const MarketingModel = () => {
  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <div className="marketing-model">
      <h2>Models</h2>
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        sx={{
          '& .MuiTabs-indicator': {
            backgroundColor: '#045137',
          },
          '& .MuiTab-root': {
            color: 'grey',
            textTransform: 'none',
            fontFamily: 'inherit',
            '&.Mui-selected': {
              color: '#045137',
            },
          },
          '& .MuiTabs-flexContainer': {
            borderBottom: '1px solid #ccc',
          },
        }}
      >
        <Tab label="Marketing Model" />
        <Tab label="Customer Model" />
        <Tab label="Revenue Model" />
        <Tab label="Operating Model" />
      </Tabs>
      <TabPanel value={selectedTab} index={0}>
        <MarketingModelHeader />
        <MarketingModelChart />       
        <MarketingModelTable />
        
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <CustomerModelHeader />
        <CustomerModelChart />
        <CustomerModelTable />
        <CustomerModelOrganicHeader />
        <CustomerModelOrganicChart />
        <CustomerModelOrganic /> 
        <CustomersTotalChart />
               
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
      <h3>Revenue Model</h3>
      <RevenueModelTable /> 
      </TabPanel>
      <TabPanel value={selectedTab} index={3}>
        <OperatingModel />
      </TabPanel>
    </div>
  );
};

export default MarketingModel;