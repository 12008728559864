import React from 'react';
import Chart from 'react-apexcharts';

const initialOrganicCustomers = 100; // Assume initial organic customers provided by user
const growthRate = 0.05; // Assume a 5% monthly growth rate
const churnRate = 0.02; // Assume a 2% monthly churn rate

const generateProjections = (initial, growthRate, churnRate, months) => {
  const projections = [];
  let current = initial;
  for (let i = 0; i < months; i++) {
    const newClients = current * growthRate;
    const churnedClients = current * churnRate;
    const closingClients = current + newClients - churnedClients;
    projections.push({
      growthRate: (newClients / current) * 100,
      churnRate: (churnedClients / current) * 100,
    });
    current = closingClients;
  }
  return projections;
};

const projections2024 = generateProjections(initialOrganicCustomers, growthRate, churnRate, 12);
const projections2025 = generateProjections(initialOrganicCustomers * Math.pow(1 + growthRate - churnRate, 12), growthRate, churnRate, 12);
const projections2026 = generateProjections(initialOrganicCustomers * Math.pow(1 + growthRate - churnRate, 24), growthRate, churnRate, 12);
const projections2027 = generateProjections(initialOrganicCustomers * Math.pow(1 + growthRate - churnRate, 36), growthRate, churnRate, 12);
const projections2028 = generateProjections(initialOrganicCustomers * Math.pow(1 + growthRate - churnRate, 48), growthRate, churnRate, 12);


const projections = [
  ...projections2024,
  ...projections2025,
  ...projections2026,
  ...projections2027,
  ...projections2028,
];



const generateQuarterlyData = (projections, rateType) => {
  const quarterlyData = [];
  for (let i = 0; i < projections.length; i += 3) {
    const quarterlySum = projections.slice(i, i + 3).reduce((sum, p) => sum + p[rateType], 0);
    quarterlyData.push(Math.round(quarterlySum * (0.9 + Math.random() * 0.2))); // Adding some irregularity
  }
  return quarterlyData;
};

const growthRates = generateQuarterlyData(projections, 'growthRate');
const churnRates = generateQuarterlyData(projections, 'churnRate');

const series = [
  {
    name: 'Growth Rate',
    data: growthRates,
  },
  {
    name: 'Churn Rate',
    data: churnRates,
  },
];

const options = {
  chart: {
    type: 'bar',
    height: 350,
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '55%',
      endingShape: 'rounded',
    },
  },
  colors: ['#3a95ff', '#d05f0d'], // Green for Growth Rate, Red for Churn Rates
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ['transparent'],
  },
  xaxis: {
    categories: [
      '2024 Q1', '2024 Q2', '2024 Q3', '2024 Q4',
      '2025 Q1', '2025 Q2', '2025 Q3', '2025 Q4',
      '2026 Q1', '2026 Q2', '2026 Q3', '2026 Q4',
      '2027 Q1', '2027 Q2', '2027 Q3', '2027 Q4',
      '2028 Q1', '2028 Q2', '2028 Q3', '2028 Q4',
    ],
  },
  yaxis: {
    title: {
      text: 'Percentage (%)',
    },
  },
  fill: {
    opacity: 1,
  },
  tooltip: {
    y: {
      formatter: function (val) {
        return val + ' %';
      },
    },
  },
};

const CustomerModelOrganicChart = () => {
  return (
    <div id="chart">
      <Chart options={options} series={series} type="bar" height={350} />
    </div>
  );
};

export default CustomerModelOrganicChart;
