import { Button } from '@mui/material';
import React from 'react';
import './CustomerModelHeader.css';


const Models = () => {
  return (
    <div className='modelHead'>
      <h3>Paid Customers</h3>
    <div className='modelHeadContent'>
      <p>2024 Growth Rate : <b>12.24%</b></p>
      <p>2024 Churn Rate : <b>23.67%</b> </p>
      </div>
      <Button variant="contained" className='changeBtn'>Change Inputs</Button>
    </div>
  );
};

export default Models;