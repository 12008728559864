import React from 'react';
import './Pricing.css';

const Pricing = () => {
  return (
    <section className="pricing">
      <h2>Plans to suit companies of all sizes</h2>
      <div className="pricing-plans">
        <div className="plan">
          <h3>Starter</h3>
          <p className="price">$199<span>/per report</span></p>
          <ul>
            <li>Up to 2 projects</li>
            <li>Basic reporting</li>
            <li>Limited support</li>
          </ul>
          <button className="cta-btn">Get Started</button>
        </div>
        <div className="plan featured">
          <h3>Growth</h3>
          <p className="price">$299<span>/per report</span></p>
          <ul>
            <li>Up to 5 projects</li>
            <li>Advanced reporting</li>
            <li>Priority support</li>
          </ul>
          <button className="cta-btn">Get Started</button>
        </div>
        <div className="plan">
          <h3>Enterprise</h3>
          <p className="price">Custom Pricing</p>
          <ul>
            <li>Unlimited projects</li>
            <li>Dedicated account manager</li>
            <li>Premium support</li>
          </ul>
          <button className="cta-btn">Contact us</button>
        </div>
      </div>
    </section>
  );
};

export default Pricing;