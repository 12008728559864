import React from 'react';
import './Download.css';
import heroAnimation from '../heroAnimation.json';
import Lottie from 'lottie-react';

const Download = () => {
  return (
    <section className="download">
      <div className="download-content">
        <h2>Join the waitlist</h2>
        <p>Say goodbye to complex financial spreadsheets and make your life easier with FinArchitect.</p>
        <a 
          href="https://forms.gle/pj7RignyMavHX5k87"
          className="cta-btn"
          target="_blank"
          rel="noopener noreferrer"
        >
          Join the Waitlist
        </a>
      </div>
      <div className="download-animation">
        <Lottie animationData={heroAnimation} />
      </div>
    </section>
  );
};

export default Download;