import React from 'react';
import './RevenueModelTable.css';

const initialOrganicCustomers = 100; // Initial organic customers provided by user
const initialPaidCustomers = 200; // Initial paid customers provided by user
const growthRate = 0.05; // Assume a 5% monthly growth rate
const churnRate = 0.02; // Assume a 2% monthly churn rate

const productDistribution = {
  Free: 0.25,
  Standard: 0.65,
  Premium: 0.10,
};

const productPrices = {
  Free: 0,
  Standard: 20, // Assume $20 for Standard
  Premium: 50, // Assume $50 for Premium
};

const generateProjections = (initial, growthRate, churnRate, months) => {
  const projections = [];
  let current = initial;
  for (let i = 0; i < months; i++) {
    const newClients = current * growthRate;
    const churnedClients = current * churnRate;
    const closingClients = current + newClients - churnedClients;
    projections.push(Math.round(closingClients));
    current = closingClients;
  }
  return projections;
};

const organicProjections = generateProjections(initialOrganicCustomers, growthRate, churnRate, 60);
const paidProjections = generateProjections(initialPaidCustomers, growthRate, churnRate, 60);

const totalProjections = organicProjections.map((organic, index) => organic + paidProjections[index]);

const distributeCustomers = (total, distribution) => {
  return {
    Free: Math.round(total * distribution.Free),
    Standard: Math.round(total * distribution.Standard),
    Premium: Math.round(total * distribution.Premium),
  };
};

const calculateRevenue = (customers, prices) => {
  return {
    Free: customers.Free * prices.Free,
    Standard: customers.Standard * prices.Standard,
    Premium: customers.Premium * prices.Premium,
    total: customers.Free * prices.Free + customers.Standard * prices.Standard + customers.Premium * prices.Premium,
  };
};

const years = ['2024', '2025', '2026', '2027', '2028'];
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const RevenueModelTable = () => {
  return (
    <table className="revenue-model-table">
      <thead>
        <tr>
          <th>Year</th>
          <th>Product</th>
          <th>Type</th>
          {months.map((month) => (
            <th key={month}>{month}</th>
          ))}
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        {years.map((year, yearIndex) => (
          ['Free', 'Standard', 'Premium'].map((product, productIndex) => {
            const customerProjections = totalProjections.slice(yearIndex * 12, (yearIndex + 1) * 12).map(total => distributeCustomers(total, productDistribution));
            return (
              <React.Fragment key={`${year}-${product}`}>
                {productIndex === 0 && <tr><td rowSpan="9"><b>{year}</b></td><td rowSpan="3">{product}</td><td>Customers</td>
                  {customerProjections.map((customers, monthIndex) => (
                    <td key={`${year}-${product}-customers-${monthIndex}`}>{customers[product]}</td>
                  ))}
                  <td>
                    {customerProjections.reduce((acc, customers) => acc + customers[product], 0)}
                  </td>
                </tr>}
                {productIndex !== 0 && <tr><td rowSpan="3">{product}</td><td>Customers</td>
                  {customerProjections.map((customers, monthIndex) => (
                    <td key={`${year}-${product}-customers-${monthIndex}`}>{customers[product]}</td>
                  ))}
                  <td>
                    {customerProjections.reduce((acc, customers) => acc + customers[product], 0)}
                  </td>
                </tr>}
                <tr><td>Price</td>
                  {Array(12).fill(productPrices[product]).map((price, monthIndex) => (
                    <td key={`${year}-${product}-price-${monthIndex}`}>{price}</td>
                  ))}
                  <td>{productPrices[product]}</td>
                </tr>
                <tr><td>Revenue</td>
                  {customerProjections.map((customers, monthIndex) => {
                    const revenue = calculateRevenue(customers, productPrices);
                    return (
                      <td key={`${year}-${product}-revenue-${monthIndex}`}>{revenue[product]}</td>
                    );
                  })}
                  <td>
                    {customerProjections.reduce((acc, customers) => {
                      const revenue = calculateRevenue(customers, productPrices);
                      return acc + revenue[product];
                    }, 0)}
                  </td>
                </tr>
              </React.Fragment>
            );
          })
        ))}
        <tr className="total-revenue-row">
          <td colSpan="3"><b>Total Revenue</b></td>
          {Array(12).fill(0).map((_, monthIndex) => (
            <td key={`total-${monthIndex}`}>
              {totalProjections.slice(monthIndex, totalProjections.length, 12).reduce((acc, total) => {
                const customers = distributeCustomers(total, productDistribution);
                const revenue = calculateRevenue(customers, productPrices);
                return acc + revenue.total;
              }, 0)}
            </td>
          ))}
          <td>
            {totalProjections.reduce((acc, total) => {
              const customers = distributeCustomers(total, productDistribution);
              const revenue = calculateRevenue(customers, productPrices);
              return acc + revenue.total;
            }, 0)}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default RevenueModelTable;
