import React from 'react';
import './CustomerModelTable.css';

const CustomerModelTable = () => {
  const years = [
    {
      year: '2024',
      beginningClients: [0, 20, 35, 46, 55, 61, 66, 69, 72, 74, 75, 77],
      newClients: [20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 240],
      churnRates: [0, 5, 9, 12, 14, 15, 16, 17, 18, 19, 19, 163],
      closingClients: [20, 35, 46, 55, 61, 66, 69, 72, 74, 75, 77, 728],
    },
    {
      year: '2025',
      beginningClients: [77, 78, 81, 84, 88, 94, 100, 106, 112, 119, 126, 133],
      newClients: [21, 22, 23, 24, 26, 27, 28, 30, 31, 33, 34, 36],
      churnRates: [20, 20, 20, 20, 20, 23, 23, 23, 25, 26, 27, 28],
      closingClients: [78, 81, 84, 88, 94, 100, 106, 112, 119, 126, 133, 140],
    },
    {
      year: '2026',
      beginningClients: [140, 149, 158, 166, 174, 181, 188, 195, 203, 210, 218, 226],
      newClients: [37, 38, 39, 40, 42, 43, 44, 45, 47, 48, 50, 51],
      churnRates: [28, 30, 31, 33, 34, 36, 37, 38, 39, 40, 42, 43],
      closingClients: [149, 158, 166, 174, 181, 188, 195, 203, 210, 218, 226, 235],
    },
    {
      year: '2027',
      beginningClients: [235, 242, 247, 253, 262, 267, 272, 278, 285, 292, 299, 306],
      newClients: [51, 51, 52, 53, 54, 55, 57, 58, 59, 60, 61, 62],
      churnRates: [44, 45, 47, 48, 50, 51, 51, 51, 52, 53, 54, 55],
      closingClients: [242, 247, 253, 258, 262, 267, 272, 278, 285, 292, 299, 306],
    },
    {
      year: '2028',
      beginningClients: [299, 306, 312, 318, 325, 332, 339, 346, 353, 360, 367, 374],
      newClients: [61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72],
      churnRates: [54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65],
      closingClients: [306, 312, 318, 325, 332, 339, 346, 353, 360, 367, 374, 381],
    },
  ];

  return (
    
    <table className="customer-model-table">
      <thead>
        <tr>
          <th></th>
          <th>Jan</th>
          <th>Feb</th>
          <th>Mar</th>
          <th>Apr</th>
          <th>May</th>
          <th>Jun</th>
          <th>Jul</th>
          <th>Aug</th>
          <th>Sep</th>
          <th>Oct</th>
          <th>Nov</th>
          <th>Dec</th>
        </tr>
      </thead>
      <tbody>
        {years.map((yearData, index) => (
          <React.Fragment key={index}>
            <tr className="year-segment">
              <td><b>{yearData.year}</b></td>
             
            </tr>
            <tr>
              <td><p>Beginning Monthly Clients</p></td>
              {yearData.beginningClients.map((value, i) => (
                <td key={`beginning-monthly-${index}-${i}`}>{value}</td>
              ))}
            </tr>
            <tr>
              <td><p>Additional New Clients</p></td>
              {yearData.newClients.map((value, i) => (
                <td key={`new-${index}-${i}`}>{value}</td>
              ))}
            </tr>
            <tr>
              <td><p>Churn Rates</p></td>
              {yearData.churnRates.map((value, i) => (
                <td key={`churn-${index}-${i}`}>{value}</td>
              ))}
            </tr>
            <tr>
              <td><p>Closing Clients</p></td>
              {yearData.closingClients.map((value, i) => (
                <td key={`closing-${index}-${i}`}>{value}</td>
              ))}
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
};

export default CustomerModelTable;
