import { Button } from '@mui/material';
import React from 'react';
import './MarketingModelHeader.css';


const Models = () => {
  return (
    <div className='modelHead'>
    <div className='modelHeadContent'>
      <p>Customer Acquisition Cost (CAC) : <b>$124.50</b></p>
      <p>Lifetime Value (LTV) : <b>$824.50</b> </p>
      </div>
      <Button variant="contained" className='changeBtn'>Change Inputs</Button>
    </div>
  );
};

export default Models;