import React from 'react';
import MarketingModel from './MarketingModel';
import Sidebar from '../Sidebar';

const Models = () => {
  return (
    <div>
      <Sidebar />
      <MarketingModel />
    </div>
  );
};

export default Models;