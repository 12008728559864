import React from 'react';
import { NavLink } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import FolderIcon from '@mui/icons-material/Folder';
import DescriptionIcon from '@mui/icons-material/Description';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import './Sidebar.css';


const tabs = [
  { name: 'Home', Icon: HomeIcon, route: '/home' },
  { name: 'Models', Icon: FolderIcon, route: '/models' },
  { name: 'Statements', Icon: DescriptionIcon, route: '/statements' },
  { name: 'Settings', Icon: SettingsIcon, route: '/settings' },
];

function Sidebar() {
  return (
    <div className="sidebar">
      <div className="logo">
        {/* <img src={logo} alt="FinArchitect Logo" /> */}
        <h3>FinArchitect</h3>
      </div>
      {tabs.map((tab) => (
        <NavLink
          key={tab.name}
          to={tab.route}
          className={({ isActive }) =>
            `sidebar-item${isActive ? ' active' : ''}`
          }
        >
          <tab.Icon className="sidebar-icon" />
          {tab.name}
        </NavLink>
      ))}
      <div className="sidebar-account">
        <AccountCircleIcon />
        Shadrack Apollo
      </div>
    </div>
  );
}

export default Sidebar;