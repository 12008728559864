import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Homepage from './components/Homepage';
import SignUp from './components/auth/Signup';
import Login from './components/auth/Login';
import CompanyDetails from './components/input/CompanyDetails';
import RevenueStreams from './components/input/RevenueStreams';
import CustomerAcquisition from './components/input/CustomerAcquisition';
import ProductServices from './components/input/ProductServices';
import Models from './components/app/models/Models';
import MarketingModelChart from './components/app/models/MarketingModelChart';
import Home from './components/app/Home';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/company-details" element={<CompanyDetails />} />
        <Route path="/revenue-streams" element={<RevenueStreams />} />
        <Route path="/customer-acquisition" element={<CustomerAcquisition />} />
        <Route path="/product-services" element={<ProductServices />} />
        <Route path="/models" element={<Models />} />
        <Route path="/chart" element={<MarketingModelChart />} />
        <Route path="/home" element={<Home />} />

      </Routes>
    </Router>
  );
}

export default App;