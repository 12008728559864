import React from 'react';
import './Mission.css';

const Mission = () => {
  return (
    <section className="mission">
      <div className="mission-content">
        <h2>A fully integrated financial modelling solution.</h2>
        <p>All the tools you need to understand the future of your business</p>
      </div>
    </section>
  );
};

export default Mission;