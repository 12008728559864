import React from 'react';
import Chart from 'react-apexcharts';

class CustomerModelChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: 'Growth Rate',
          data: [10, 15, 12, 18, 14, 16, 13, 20, 15, 18, 14, 17, 16, 18, 20, 22, 19, 21, 20, 23], // Example data
        },
        {
          name: 'Churn Rates',
          data: [5, 7, 6, 8, 7, 8, 7, 9, 8, 9, 7, 8, 9, 10, 11, 12, 10, 11, 12, 13], // Example data
        },
      ],
      options: {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
          },
        },
        colors: ['#4caf50', '#d05f0d'], // Green for Growth Rate, Red for Churn Rates
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories: [
            '2024 Q1', '2024 Q2', '2024 Q3', '2024 Q4',
            '2025 Q1', '2025 Q2', '2025 Q3', '2025 Q4',
            '2026 Q1', '2026 Q2', '2026 Q3', '2026 Q4',
            '2027 Q1', '2027 Q2', '2027 Q3', '2027 Q4',
            '2028 Q1', '2028 Q2', '2028 Q3', '2028 Q4',
          ],
        },
        yaxis: {
          title: {
            text: 'Percentage (%)',
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + ' %';
            },
          },
        },
      },
    };
  }

  render() {
    return (
      <div>
        <div id="chart">
          <Chart options={this.state.options} series={this.state.series} type="bar" height={350} />
        </div>
        <div id="html-dist"></div>
      </div>
    );
  }
}

export default CustomerModelChart;
