import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import './CustomerAcquisition.css';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

const CustomerAcquisition = () => {
  const [marketingSpend, setMarketingSpend] = useState('');
  const [projectedCustomerAcquisition, setProjectedCustomerAcquisition] = useState('');
  const [churnRate, setChurnRate] = useState('');

  const calculateIndustryAverage = (field) => {
    // Replace these values with actual industry averages
    const industryAverages = {
      marketingSpend: '10000',
      projectedCustomerAcquisition: '500',
      churnRate: '5',
    };

    return industryAverages[field];
  };

  const handleCalculate = (field) => {
    const industryAverage = calculateIndustryAverage(field);

    switch (field) {
      case 'marketingSpend':
        setMarketingSpend(industryAverage);
        break;
      case 'projectedCustomerAcquisition':
        setProjectedCustomerAcquisition(industryAverage);
        break;
      case 'churnRate':
        setChurnRate(industryAverage);
        break;
      default:
        break;
    }
  };

  return (
    <div className="customer-acquisition-container">
    <div className="customer-acquisition">
      <h2>Customer Acquisition Costs</h2>
      <p>Step 3 of 5</p>
      <div className="input-field">
        <label>
          Monthly Marketing Spend
          <Tooltip title="Click calculate to populate the industry average">
            <span className="calculate-button" onClick={() => handleCalculate('marketingSpend')}>
              Calculate
            </span>
          </Tooltip>
        </label>
        <input
          type="number"
          value={marketingSpend}
          onChange={(e) => setMarketingSpend(e.target.value)}
        />
      </div>
      <div className="input-field">
        <label>
          Projected Acquired Customers 
          <Tooltip title="Click calculate to populate the industry average">
            <span
              className="calculate-button"
              onClick={() => handleCalculate('projectedCustomerAcquisition')}
            >
              Calculate
            </span>
          </Tooltip>
        </label>
        <input
          type="number"
          value={projectedCustomerAcquisition}
          onChange={(e) => setProjectedCustomerAcquisition(e.target.value)}
        />
      </div>
      <div className="input-field">
        <label>
          Churn rate
          <Tooltip title="Click calculate to populate the industry average">
            <span className="calculate-button" onClick={() => handleCalculate('churnRate')}>
              Calculate
            </span>
          </Tooltip>
        </label>
        <input type="number" value={churnRate} onChange={(e) => setChurnRate(e.target.value)} />
      </div>
     <Link to ="/models"> <Button className="continue-button">Continue</Button></Link>
    </div>
    </div>
  );
};

export default CustomerAcquisition;