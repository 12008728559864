import React from 'react';
import './Login.css'; // assuming you'll create a new CSS file for login styles
import heroAnimation from '../../heroAnimation.json';
import Lottie from 'lottie-react';
import { Link } from 'react-router-dom';

const Login = () => {
  return (
    <div className="loginSection">
      <div className="sign-up">
        <h1>FinArchitect Login</h1>
        <div className="input-group">
          <input type="email" placeholder="Email" />
          <input type="password" placeholder="Password" />
          <button>Login</button>
          <button className='google-btn'>Login with Google</button>        
        </div>
        <p>Don't have an account? <Link to="/signup">Sign Up</Link></p>
      </div>
      <div className='signup-image'>
        <Lottie animationData={heroAnimation} />
      </div>
    </div>
  );
};

export default Login;
