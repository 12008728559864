import React from 'react';
import './CustomerModelOrganic.css';

const initialOrganicCustomers = 100; // Assume initial organic customers provided by user
const growthRate = 0.05; // Assume a 5% monthly growth rate
const churnRate = 0.02; // Assume a 2% monthly churn rate

const generateProjections = (initial, growthRate, churnRate, months) => {
  const projections = [];
  let current = initial;
  for (let i = 0; i < months; i++) {
    const newClients = current * growthRate;
    const churnedClients = current * churnRate;
    const closingClients = current + newClients - churnedClients;
    projections.push({
      beginning: Math.round(current),
      new: Math.round(newClients),
      churn: Math.round(churnedClients),
      closing: Math.round(closingClients),
    });
    current = closingClients;
  }
  return projections;
};

const years = [
  {
    year: '2024',
    projections: generateProjections(initialOrganicCustomers, growthRate, churnRate, 12),
  },
  {
    year: '2025',
    projections: generateProjections(initialOrganicCustomers * Math.pow(1 + growthRate - churnRate, 12), growthRate, churnRate, 12),
  },
  {
    year: '2026',
    projections: generateProjections(initialOrganicCustomers * Math.pow(1 + growthRate - churnRate, 24), growthRate, churnRate, 12),
  },
  {
    year: '2027',
    projections: generateProjections(initialOrganicCustomers * Math.pow(1 + growthRate - churnRate, 36), growthRate, churnRate, 12),
  },
  {
    year: '2028',
    projections: generateProjections(initialOrganicCustomers * Math.pow(1 + growthRate - churnRate, 48), growthRate, churnRate, 12),
  },
];

const CustomerModelOrganic = () => {
  return (
    <table className="customer-model-organic-table">
      <thead>
        <tr>
          <th></th>
          <th>Jan</th>
          <th>Feb</th>
          <th>Mar</th>
          <th>Apr</th>
          <th>May</th>
          <th>Jun</th>
          <th>Jul</th>
          <th>Aug</th>
          <th>Sep</th>
          <th>Oct</th>
          <th>Nov</th>
          <th>Dec</th>
        </tr>
      </thead>
      <tbody>
        {years.map((yearData, index) => (
          <React.Fragment key={index}>
            <tr className="year-segment">
              <td><b>{yearData.year}</b></td>
             
            </tr>
            <tr>
              <td><p>Beginning Monthly Clients</p></td>
              {yearData.projections.map((value, i) => (
                <td key={`beginning-monthly-${index}-${i}`}>{value.beginning}</td>
              ))}
            </tr>
            <tr>
              <td><p>Additional New Clients</p></td>
              {yearData.projections.map((value, i) => (
                <td key={`new-${index}-${i}`}>{value.new}</td>
              ))}
            </tr>
            <tr>
              <td><p>Churn Rates</p></td>
              {yearData.projections.map((value, i) => (
                <td key={`churn-${index}-${i}`}>{value.churn}</td>
              ))}
            </tr>
            <tr>
              <td><p>Closing Clients</p></td>
              {yearData.projections.map((value, i) => (
                <td key={`closing-${index}-${i}`}>{value.closing}</td>
              ))}
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
};

export default CustomerModelOrganic;
