import React from 'react';
import './Signup.css';
import heroAnimation from '../../heroAnimation.json';
import Lottie from 'lottie-react';
import { Link } from 'react-router-dom';

const SignUp = () => {
  return (
    <div className="signupSection">
    <div className="sign-up">
      <h1>FinArchitect Sign up</h1>
      <div className="input-group">
          <input type="text" placeholder="Name" />
          <input type="email" placeholder="Email" />
          <input type="password" placeholder="Password" />
          <Link to ="/company-details"><button>Create account</button></Link>
          <button className='google-btn'>Sign up with Google</button>        
     </div>
    <p>Already have an account? <Link to ="/login"><a href="/login">Login</a></Link></p>
    </div>
    <div className='signup-image'>
      <Lottie animationData={heroAnimation} />
    </div>
    </div>
  );
};

export default SignUp;