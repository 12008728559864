import React, { useState } from 'react';
import './RevenueStreams.css';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

const RevenueStreams = () => {
  const [productName, setProductName] = useState('');
  const [unitPrice, setUnitPrice] = useState('');
  const [monthlySalesVolume, setMonthlySalesVolume] = useState('');
  const [revenueStreams, setRevenueStreams] = useState([]);

  const handleAddRevenueStream = () => {
    if (productName && unitPrice && monthlySalesVolume) {
      const newRevenueStream = {
        productName,
        unitPrice: parseFloat(unitPrice),
        monthlySalesVolume: parseInt(monthlySalesVolume),
      };
      setRevenueStreams([...revenueStreams, newRevenueStream]);
      setProductName('');
      setUnitPrice('');
      setMonthlySalesVolume('');
    }
  };

  const calculateTotalRevenue = () => {
    return revenueStreams.reduce((total, stream) => {
      return total + stream.unitPrice * stream.monthlySalesVolume;
    }, 0);
  };

  return (
    <div className="revenue-streams">
     
      <div className="input-fields">
        <h2>Calculate your monthly revenue</h2>
        <p>Step 2 of 5</p>
        <h3>Add all the products and services you sell</h3>
       
        <div className='input-group'>
        <input
          type="text"
          placeholder="Product/Service Name"
          value={productName}
          onChange={(e) => setProductName(e.target.value)}
        />
        <input
          type="number"
          placeholder="Unit price"
          value={unitPrice}
          onChange={(e) => setUnitPrice(e.target.value)}
        />
        <input
          type="number"
          placeholder="Sales Volume"
          value={monthlySalesVolume}
          onChange={(e) => setMonthlySalesVolume(e.target.value)}
        />
        <button onClick={handleAddRevenueStream}>Add</button>
        </div>
      </div>
      <div className="revenue-streams-list">
        <h3>Revenue Streams</h3>
        <table>
          <thead>
            <tr>
              <th>Product</th>
              <th>Price</th>
              <th>Sales Volume</th>
              <th>Total Revenue</th>
            </tr>
          </thead>
          <tbody>
            {revenueStreams.map((stream, index) => (
              <tr key={index}>
                <td>{stream.productName}</td>
                <td>${stream.unitPrice}</td>
                <td>{stream.monthlySalesVolume}</td>
                <td>${stream.unitPrice * stream.monthlySalesVolume}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <p className="total-revenue">Total Monthly Revenue: ${calculateTotalRevenue()}</p>
      </div>
      <div className="continue-button-container">
      <Link to="/customer-acquisition"><Button className="continuebutton">Continue</Button></Link>
      </div>
    </div>
  );
};

export default RevenueStreams;