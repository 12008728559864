import React from 'react';
import './MarketingModelTable.css';

const MarketingModelTable = () => {
  const years = [
    { year: '2024', growth: 1, customers: [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100] },
    { year: '2025', growth: 2.1, customers: [100, 100, 100, 105, 110, 115, 120, 125, 130, 135, 140, 145] },
    { year: '2026', growth: 1.8, customers: [150, 152, 154, 156, 158, 160, 162, 164, 166, 168, 170, 172] },
    { year: '2027', growth: 1.8, customers: [174, 176, 178, 180, 182, 184, 186, 188, 190, 192, 194, 196] },
    { year: '2028', growth: 1.8, customers: [198, 200, 202, 204, 206, 208, 210, 212, 214, 216, 218, 220] },
  ];

  return (
   
    <table>
      
      <thead>
        <tr>
          <th></th>
          <th>Jan</th>
          <th>Feb</th>
          <th>Mar</th>
          <th>Apr</th>
          <th>May</th>
          <th>Jun</th>
          <th>Jul</th>
          <th>Aug</th>
          <th>Sep</th>
          <th>Oct</th>
          <th>Nov</th>
          <th>Dec</th>
        </tr>
      </thead>
      <tbody>
        {years.map((yearData, index) => (
          <tr key={index}>
           <td> <b>{yearData.year}</b></td>
            {yearData.customers.map((customer, i) => (
              <td key={`${yearData.year}-${i}`}>{customer}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default MarketingModelTable;