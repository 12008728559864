import React from 'react';
import './Features.css';

const Features = () => {
  return (
    <section className="features">
      <h2>Easy Modeling Steps</h2>
      <div className="features-grid">
        <div className="feature">
          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M1 9V6.4A2.4 2.4 0 0 1 3.4 4h17.2A2.4 2.4 0 0 1 23 6.4v11.2a2.4 2.4 0 0 1-2.4 2.4H3.4A2.4 2.4 0 0 1 1 17.6V15" stroke="#d05f0d" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"></path><path d="m13 16 4-4-4-4" stroke="#d05f0d" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M17 12H1" stroke="#d05f0d" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"></path></g></svg>
          <h3>Input Data</h3>
          <p>Streamline your data imports with our intuitive interface.</p>
        </div>
        <div className="feature">
          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M1 9V6.4A2.4 2.4 0 0 1 3.4 4h17.2A2.4 2.4 0 0 1 23 6.4v11.2a2.4 2.4 0 0 1-2.4 2.4H3.4A2.4 2.4 0 0 1 1 17.6V15" stroke="#d05f0d" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"></path><path d="m13 16 4-4-4-4" stroke="#d05f0d" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M17 12H1" stroke="#d05f0d" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"></path></g></svg>
          <h3>Analyze Trends</h3>
          <p>Our software analyzes patterns in your data to provide valuable insights.</p>
        </div>
        <div className="feature">
          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M1 9V6.4A2.4 2.4 0 0 1 3.4 4h17.2A2.4 2.4 0 0 1 23 6.4v11.2a2.4 2.4 0 0 1-2.4 2.4H3.4A2.4 2.4 0 0 1 1 17.6V15" stroke="#d05f0d" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"></path><path d="m13 16 4-4-4-4" stroke="#d05f0d" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M17 12H1" stroke="#d05f0d" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"></path></g></svg>
          <h3>Make Decisions</h3>
          <p>Leverage insights to make informed decisions for your business.</p>
        </div>
      </div>
    </section>
  );
};

export default Features;