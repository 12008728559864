import React from 'react';
import './Hero.css';
import heroAnimation from '../heroAnimation2.json';
import Lottie from 'lottie-react';

const Hero = () => {
  return (
    <section className="hero">
      <div className="hero-content">
        <h1>Financial Modelling,<br /> Made Easy</h1>
        <p>FinArchitect is an easy to use yet powerful financial modelling software. Save time making complex financial spreadsheets and focus on making decisions for your business.</p>
        <a 
          className="cta-btn" 
          href='https://forms.gle/pj7RignyMavHX5k87'
          target="_blank"
          rel="noopener noreferrer"
        >
          Get a Financial Model
        </a>
      </div>
      <div className="hero-image">
         <Lottie animationData={heroAnimation} />
      </div>
    </section>
  );
};

export default Hero;