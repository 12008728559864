import React from 'react';
import Chart from 'react-apexcharts';

// Sample data for organic and paid customers projections
const initialOrganicCustomers = 100; // Assume initial organic customers provided by user
const growthRate = 0.05; // Assume a 5% monthly growth rate
const churnRate = 0.02; // Assume a 2% monthly churn rate

const generateProjections = (initial, growthRate, churnRate, months) => {
  const projections = [];
  let current = initial;
  for (let i = 0; i < months; i++) {
    const newClients = current * growthRate;
    const churnedClients = current * churnRate;
    const closingClients = current + newClients - churnedClients;
    projections.push({
      beginning: Math.round(current),
      new: Math.round(newClients),
      churn: Math.round(churnedClients),
      closing: Math.round(closingClients),
    });
    current = closingClients;
  }
  return projections;
};

// Generate projections for organic customers
const organicProjections2024 = generateProjections(initialOrganicCustomers, growthRate, churnRate, 12);
const organicProjections2025 = generateProjections(initialOrganicCustomers * Math.pow(1 + growthRate - churnRate, 12), growthRate, churnRate, 12);
const organicProjections2026 = generateProjections(initialOrganicCustomers * Math.pow(1 + growthRate - churnRate, 24), growthRate, churnRate, 12);
const organicProjections2027 = generateProjections(initialOrganicCustomers * Math.pow(1 + growthRate - churnRate, 36), growthRate, churnRate, 12);
const organicProjections2028 = generateProjections(initialOrganicCustomers * Math.pow(1 + growthRate - churnRate, 48), growthRate, churnRate, 12);

// Generate projections for paid customers (sample data)
const paidProjections2024 = [
  { closing: 200 }, { closing: 220 }, { closing: 250 }, { closing: 280 },
  { closing: 300 }, { closing: 330 }, { closing: 360 }, { closing: 400 },
  { closing: 420 }, { closing: 450 }, { closing: 480 }, { closing: 500 },
];
const paidProjections2025 = [
  { closing: 520 }, { closing: 540 }, { closing: 560 }, { closing: 580 },
  { closing: 600 }, { closing: 620 }, { closing: 640 }, { closing: 660 },
  { closing: 680 }, { closing: 700 }, { closing: 720 }, { closing: 740 },
];
const paidProjections2026 = [
  { closing: 760 }, { closing: 780 }, { closing: 800 }, { closing: 820 },
  { closing: 840 }, { closing: 860 }, { closing: 880 }, { closing: 900 },
  { closing: 920 }, { closing: 940 }, { closing: 960 }, { closing: 980 },
];
const paidProjections2027 = [
  { closing: 1000 }, { closing: 1020 }, { closing: 1040 }, { closing: 1060 },
  { closing: 1080 }, { closing: 1100 }, { closing: 1120 }, { closing: 1140 },
  { closing: 1160 }, { closing: 1180 }, { closing: 1200 }, { closing: 1220 },
];
const paidProjections2028 = [
  { closing: 1240 }, { closing: 1260 }, { closing: 1280 }, { closing: 1300 },
  { closing: 1320 }, { closing: 1340 }, { closing: 1360 }, { closing: 1380 },
  { closing: 1400 }, { closing: 1420 }, { closing: 1440 }, { closing: 1460 },
];

const sumQuarterly = (projections) => {
  return [
    projections.slice(0, 3).reduce((sum, p) => sum + p.closing, 0),
    projections.slice(3, 6).reduce((sum, p) => sum + p.closing, 0),
    projections.slice(6, 9).reduce((sum, p) => sum + p.closing, 0),
    projections.slice(9, 12).reduce((sum, p) => sum + p.closing, 0),
  ];
};

const organicCustomers = [
  ...sumQuarterly(organicProjections2024),
  ...sumQuarterly(organicProjections2025),
  ...sumQuarterly(organicProjections2026),
  ...sumQuarterly(organicProjections2027),
  ...sumQuarterly(organicProjections2028),
];

const paidCustomers = [
  ...sumQuarterly(paidProjections2024),
  ...sumQuarterly(paidProjections2025),
  ...sumQuarterly(paidProjections2026),
  ...sumQuarterly(paidProjections2027),
  ...sumQuarterly(paidProjections2028),
];

class CustomersTotalChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: 'Organic Customers',
          data: organicCustomers,
        },
        {
          name: 'Paid Customers',
          data: paidCustomers,
        },
      ],
      options: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            borderRadiusApplication: 'end', // 'around', 'end'
            borderRadiusWhenStacked: 'last', // 'all', 'last'
            dataLabels: {
              total: {
                enabled: true,
                style: {
                  fontSize: '13px',
                  fontWeight: 900,
                },
              },
            },
          },
        },
        xaxis: {
          categories: [
            '2024 Q1', '2024 Q2', '2024 Q3', '2024 Q4',
            '2025 Q1', '2025 Q2', '2025 Q3', '2025 Q4',
            '2026 Q1', '2026 Q2', '2026 Q3', '2026 Q4',
            '2027 Q1', '2027 Q2', '2027 Q3', '2027 Q4',
            '2028 Q1', '2028 Q2', '2028 Q3', '2028 Q4',
          ],
        },
        legend: {
          position: 'bottom',
          offsetY: 0,
        },
        fill: {
          opacity: 1,
        },
      },
    };
  }

  render() {
    return (
      <div>
        <div id="chart">
          <Chart options={this.state.options} series={this.state.series} type="bar" height={350} />
        </div>
        <div id="html-dist"></div>
      </div>
    );
  }
}

export default CustomersTotalChart;
