import React, { useState } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import './CompanyDetails.css';
import { Link } from 'react-router-dom';

const CompanyDetails = () => {
  const [industry, setIndustry] = useState('');
  const [stage, setStage] = useState('');
  const [size, setSize] = useState('');
  const [country, setCountry] = useState('');
  const [currency, setCurrency] = useState('');

  return (
    <div className="company-details">
      <h1>Set up your Company</h1>
      <div className="input-group">
        <h2>Company Details</h2>
        <TextField
          label="Company Name"
          variant="outlined"
          className="input-field"
        />
        <FormControl className="form-control">
          <InputLabel id="stage-label">Stage</InputLabel>
          <Select
            labelId="stage-label"
            value={stage}
            onChange={(e) => setStage(e.target.value)}
          >
            <MenuItem value="">Select Stage</MenuItem>
            <MenuItem value="Seed">Seed</MenuItem>
            <MenuItem value="Series A">Series A</MenuItem>
            <MenuItem value="Series B">Series B</MenuItem>
            <MenuItem value="Series C">Series C</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Year Founded"
          type="number"
          variant="outlined"
          className="input-field"
        />
        <FormControl className="form-control">
          <InputLabel id="industry-label">Industry</InputLabel>
          <Select
            labelId="industry-label"
            value={industry}
            onChange={(e) => setIndustry(e.target.value)}
          >
            <MenuItem value="">Select Industry</MenuItem>
            <MenuItem value="Technology">Technology</MenuItem>
            <MenuItem value="Healthcare">Healthcare</MenuItem>
            <MenuItem value="Finance">Finance</MenuItem>
            <MenuItem value="Retail">Retail</MenuItem>
          </Select>
        </FormControl>
        <FormControl className="form-control">
          <InputLabel id="size-label">Size</InputLabel>
          <Select
            labelId="size-label"
            value={size}
            onChange={(e) => setSize(e.target.value)}
          >
            <MenuItem value="">Select Size</MenuItem>
            <MenuItem value="1-10">1-10</MenuItem>
            <MenuItem value="11-50">11-50</MenuItem>
            <MenuItem value="51-200">51-200</MenuItem>
            <MenuItem value="201-500">201-500</MenuItem>
            <MenuItem value="500+">500+</MenuItem>
          </Select>
        </FormControl>
        <FormControl className="form-control">
          <InputLabel id="country-label">Country</InputLabel>
          <Select
            labelId="country-label"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          >
            <MenuItem value="">Select Country</MenuItem>
            <MenuItem value="United States">United States</MenuItem>
            <MenuItem value="United Kingdom">United Kingdom</MenuItem>
            <MenuItem value="Canada">Canada</MenuItem>
            <MenuItem value="Australia">Australia</MenuItem>
          </Select>
        </FormControl>
        <FormControl className="form-control">
          <InputLabel id="currency-label">Currency</InputLabel>
          <Select
            labelId="currency-label"
            value={currency}
            onChange={(e) => setCurrency(e.target.value)}
          >
            <MenuItem value="">Select Currency</MenuItem>
            <MenuItem value="USD">USD</MenuItem>
            <MenuItem value="GBP">GBP</MenuItem>
            <MenuItem value="EUR">EUR</MenuItem>
            <MenuItem value="AUD">AUD</MenuItem>
          </Select>
        </FormControl>
        <Link to="/product-services"><Button variant="contained"  className="continue-button">
          Continue
        </Button></Link>
      </div>
    </div>
  );
};

export default CompanyDetails;