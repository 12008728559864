import React, { useState } from 'react';
import './Header.css';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      <div className="logo">
        <img src='./finarchitechlogo.png' alt='FinArchitect' width={300} />
      </div>
      <nav className={`nav ${isMenuOpen ? 'nav-open' : ''}`}>
        <ul>
          <li><a href="#overview" onClick={toggleMenu}>Overview</a></li>
          <li><a href="#start" onClick={toggleMenu}>Get Started</a></li>
          <li><a href="#studies" onClick={toggleMenu}>Case Studies</a></li>
          <li className="mobile-only">
            <a 
              href="https://forms.gle/pj7RignyMavHX5k87"
              className="signup"
              target="_blank"
              rel="noopener noreferrer"
              onClick={toggleMenu}
            >
              Get a Financial Model
            </a>
          </li>
        </ul>
      </nav>
      <div className="buttons desktop-only">
        <a 
          href="https://forms.gle/pj7RignyMavHX5k87"
          className="signup"
          target="_blank"
          rel="noopener noreferrer"
        >
          Get a Financial Model
        </a>
      </div>
      <button className="menu-toggle" onClick={toggleMenu}>
        {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
      </button>
    </header>
  );
};

export default Header;