import React from 'react';
import './OperatingModel.css';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const OperatingModel = () => {
  const operatingData = [
    { year: 2024, revenue: 1000000, costs: 800000, profit: 200000, margin: '20%' },
    { year: 2025, revenue: 1500000, costs: 1100000, profit: 400000, margin: '26.7%' },
    { year: 2026, revenue: 2200000, costs: 1500000, profit: 700000, margin: '31.8%' },
    { year: 2027, revenue: 3000000, costs: 2000000, profit: 1000000, margin: '33.3%' },
    { year: 2028, revenue: 4000000, costs: 2600000, profit: 1400000, margin: '35%' },
  ];

  return (
    <div className="operating-model">
      <h2>Operating Model</h2>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Year</TableCell>
              <TableCell align="right">Revenue</TableCell>
              <TableCell align="right">Costs</TableCell>
              <TableCell align="right">Profit</TableCell>
              <TableCell align="right">Margin</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {operatingData.map((row) => (
              <TableRow key={row.year}>
                <TableCell component="th" scope="row">
                  {row.year}
                </TableCell>
                <TableCell align="right">${row.revenue.toLocaleString()}</TableCell>
                <TableCell align="right">${row.costs.toLocaleString()}</TableCell>
                <TableCell align="right">${row.profit.toLocaleString()}</TableCell>
                <TableCell align="right">{row.margin}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default OperatingModel;
