import React from 'react';
import Sidebar from './Sidebar';
import HomeContent from './HomeContent';
import './Home.css';

const Home = () => {
  return (
    <div>
      <Sidebar />
      <HomeContent />
    </div>
  );
};

export default Home;