import React from 'react';
import './HomeContent.css';
import { Link } from 'react-router-dom';
import ChannelIcon from '@mui/icons-material/SettingsInputAntenna';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BarChartIcon from '@mui/icons-material/BarChart';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import DownloadIcon  from '@mui/icons-material/Download';

function HomeContent() {
  return (
    <div className="home">
      <h1>Welcome to FinArchitect &#128075; Complete these steps and build your investor-ready model!</h1>
      
      <div className="step">
        <ChannelIcon />
        <div>
          <Link to="/acquisition">Add your acquisition channel</Link>
          <p className="small-text">How do you plan to acquire your customers?</p>
        </div>
      </div>
      
      <div className="step">
        <AttachMoneyIcon />
        <div>
          <Link to="/revenues">Add your revenue streams</Link>
          <p className="small-text">Add your pricing plans and additional ways you plan to make money</p>
        </div>
      </div>
      
      <div className="step">  
        <BarChartIcon />
        <div>
          <Link to="/expenses">Add your expenses</Link>
          <p className="small-text">I know, no one likes expenses but it's important you include them.</p>
        </div>
      </div>
      
      <div className="step">
        <ViewInArIcon /> 
        <div>
          <Link to="/captable">Setup your Cap Table</Link>
          <p className="small-text">Establish your founding team equity and plan for future investments</p>
        </div>
      </div>
      
      <h2>Download your financial model!</h2>
      <div className="step">
        <DownloadIcon />
        <div>
          <Link to="/investorprep">Download Model</Link>
          <p className="small-text">Download your investor ready financial model as an excel sheet here</p>
        </div>  
      </div>
    
    </div>
  );
}

export default HomeContent;