import React, { useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import './ProductServices.css';
import { Link } from 'react-router-dom';

const ProductServices = () => {
  const [selectedOption, setSelectedOption] = useState('');

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleContinue = () => {
    // Handle continue button click
    console.log('Selected option:', selectedOption);
  };

  return (
    <div className="product-services">
         <div className='radio-group'>
      <FormControl component="fieldset">
     
        <FormLabel component="legend" className="form-label">
          What does your company sell?
        </FormLabel>
        
        <RadioGroup
          aria-label="product-services"
          name="product-services"
          value={selectedOption}
          onChange={handleOptionChange}
        >
          <FormControlLabel
            value="products"
            control={<Radio />}
            label="Products"
            className="radio-label"
          />
          <FormControlLabel
            value="services"
            control={<Radio />}
            label="Services"
            className="radio-label"
          />
          <FormControlLabel
            value="both"
            control={<Radio />}
            label=" Both Products and Services"
            className="radio-label"
          />
        </RadioGroup>
       
      </FormControl>
      <Link to="/revenue-streams">
      <Button
        variant="contained"
        className="continue-button"
        onClick={handleContinue}
      >
        Continue
      </Button>
      </Link>
      </div>
    </div>
  );
};

export default ProductServices;